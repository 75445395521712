import { MyPdfMake } from "./MyPdfMake";

export class MyReportGroup extends MyPdfMake {
    _titulo;
    _subtitulos = [];
    _columnas = [];
    _filas = [];
    _hayFilaTotales = false;
    _bordesLineasContenido = null;
    _separarDeLosAnteriores = false;
    _fontSizeContenidoPrincipal = 7;
    _saltoPagina = null;

    public compruebaNumColumnasFila(datosFila) {
        if (datosFila.length !== this._columnas.length) {
            /* swal({
                title: "El número de columnas de la fila no coincide con el número de columnas del informe",
                type: "danger"
            }); */

            alert("El número de columnas de la fila no coincide con el número de columnas del informe");
        }
    }

    private getAnchosColumnas() {
        let anchosColumnas = [];

        let numColumnas = this._columnas.length;
        for (let i = 0; i < numColumnas; i++)
            anchosColumnas.push(this._columnas[i].ancho)

        return anchosColumnas;
    }

    private getTitulosColumnas(fontSize) {
        let titulosColumnas = [];

        let numColumnas = this._columnas.length;
        for (let i = 0; i < numColumnas; i++) {
            let texto = this.getTexto(
                this._columnas[i].texto,
                undefined,
                fontSize,
                this._columnas[i].negrita,
                this._columnas[i].alineacion);
            titulosColumnas.push(texto);
        }

        return titulosColumnas;
    }

    public getTablaContenidoPrincipal(fontSize) {
        let anchosColumnas = this.getAnchosColumnas();
        let titulosColumnas = this.getTitulosColumnas(fontSize);

        this._filas.unshift(titulosColumnas);

        return this.getTabla(anchosColumnas, this._filas, undefined, fontSize);
    }

    public setSaltoPagina(saltoPagina) {
        this._saltoPagina = saltoPagina;
    }

    public setFontSizeContenidoPrincipal(fontSize) {
        this._fontSizeContenidoPrincipal = fontSize;
    }

    public setBordesLineasContenido(bordes) {
        this._bordesLineasContenido = bordes;
    }

    public setSepararDeLosAnteriores() {
        this._separarDeLosAnteriores = true;
    }

    public setTituloGrupo(texto, alineacion) {
        let margin = [15, 5, 15, 5];
        let fontSize = 12;
        let bold = true;
        let id = null;
        let pageBreak = null;

        if (this._saltoPagina === 'before')
            pageBreak = this._saltoPagina;

        this._titulo = this.getTexto(texto, margin, fontSize, bold, alineacion, undefined, pageBreak);
    }

    public addSubtituloGrupo(texto) {
        let alignment = 'left';
        let margin = [15, 5, 15, 5];
        let fontSize = 12;
        let bold = false;
        let subtitulo = this.getTexto(texto, margin, fontSize, bold, alignment);

        this._subtitulos.push(subtitulo);
    }

    public addColumna(texto, negrita?: boolean, ancho?: number | string, alineacion?: string) {
        let columna = {
            "texto": texto,
            "negrita": negrita != null? negrita: true,
            "ancho": ancho != null? ancho: 'auto',
            "alineacion": alineacion != null? alineacion: 'left'
        }

        this._columnas.push(columna);
    }

    public addFilaContenido(datosFila, fontSize) {
        this.compruebaNumColumnasFila(datosFila);

        let numColumnas = datosFila.length;
        let fila = [];

        for (let i = 0; i < numColumnas; i++) {
            let texto;
            if (i === numColumnas - 1 && this._saltoPagina === 'after')
                texto = this.getTexto(datosFila[i], undefined, fontSize, undefined, this._columnas[i].alineacion, undefined, 'after');
            else
                texto = this.getTexto(datosFila[i], undefined, fontSize, undefined, this._columnas[i].alineacion);
            fila.push(texto);
        }

        this._filas.push(fila);
    }

    public addCabeceraSubgrupo(texto, fontSize, negrita, alineacion) {
        let colSpan = this._columnas.length;
        let fila = [];
        let columna = this.getTexto(texto, undefined, fontSize, negrita, alineacion, undefined, undefined, colSpan);
        fila.push(columna);
        this._filas.push(fila);
    }

    public addFilaTotales(datosFila, fontSize) {
        this.compruebaNumColumnasFila(datosFila);

        let numColumnas = datosFila.length;
        let fila = [];

        for (let i = 0; i < numColumnas; i++) {
            let texto = this.getTexto(datosFila[i], undefined, fontSize, true, this._columnas[i].alineacion);
            fila.push(texto);
        }

        this._filas.push(fila);

        this._hayFilaTotales = true;
    }

    public getContenidoGrupo() {
        let contenido = [];

        if (this._titulo != null)
            contenido.push(this._titulo);

        if (this._subtitulos.length > 0)
            contenido.push(this._subtitulos);

        let tablaContenidoPrincipal = this.getTablaContenidoPrincipal(this._fontSizeContenidoPrincipal);

        contenido.push(tablaContenidoPrincipal);

        return contenido;
    }
}
