import { IConductor } from "./entity/conductor.interface";
import { EventoScheduler } from "./helper/dhtmlxHelper/evento-scheduler.class";
import { RutaEventos } from "./business-logic-layer/asignacion-automatica-servicios/ruta-servicios.class";

import * as _ from "lodash";
import { DatosFiltroAsigScheduler } from "./datos-filtro-asig-scheduler.class";
import { IServicio } from "./entity/servicio.interface";
import { IEventoScheduler } from "./helper/dhtmlxHelper/evento-scheduler.interface";
import { EventoSchedulerBll } from "./business-logic-layer/evento-scheduler.bll";
import { EventoSchedulerServicio } from "./helper/dhtmlxHelper/evento-scheduler-servicio.class";
import { IAgrupacionCompartidos } from "./entity/agrupacion-compartidos.interface";
import { EventoSchedulerAgrupacion } from "./helper/dhtmlxHelper/evento-scheduler-agrupacion.class";

export class EstadoVistaAsig {
    datosFiltro: DatosFiltroAsigScheduler;
    rutas: RutaEventos[];
    conductores:  IConductor[];
    eventos: EventoScheduler[];
    titulo: string;
    fecha: Date;

    public constructor(
        rutas: RutaEventos[],
        conductores:  IConductor[],
        eventos: EventoScheduler[],
        titulo?: string,
    ) {
        this.limpiarDatosFiltro();
        this.rutas = rutas;
        this.conductores = conductores;
        this.eventos = eventos;

        if(titulo)
            this.titulo = titulo;

        this.fecha = new Date();
        this.corregirReferencias();
    }

    public static fromJsonObject(objeto: any): EstadoVistaAsig {
        let estado = new EstadoVistaAsig([], [], []);
        estado = Object.assign(estado, objeto);
        estado.rutas = estado.rutas.map((ruta) => RutaEventos.fromJsonObject(ruta));
        estado.eventos = estado.eventos.map((evento) => EventoSchedulerBll.fromJsonObject(evento));
        estado.corregirReferencias();

        return estado;
    }

    public corregirReferencias() {
        let evetosScheduler = this.eventos;

        this.rutas.forEach((ruta) => {
            let eventosReferenciados = ruta.getEventos().map((eventoRuta) => 
                evetosScheduler.find((eventoScheduler) => eventoScheduler.id === eventoRuta.id)
            );
            ruta.setEventos(eventosReferenciados);
        });
    }

    public limpiarDatosFiltro() {
        this.datosFiltro = new DatosFiltroAsigScheduler();
    }

    public limpiarDatosScheduler() {
        this.rutas = [];
        this.conductores = [];
        this.eventos = [];
    }

    public clonar() {
        return _.cloneDeep(this);
    }

    public capturarEstado(nuevoTitulo?: string) {
        let clone = this.clonar();
        clone.fecha = new Date();

        if(nuevoTitulo)
            clone.titulo = nuevoTitulo;
            
        return clone;
    }

    public getEventosAlterados(): IEventoScheduler[] {
        return this.eventos.filter((e) => e.opciones.modificado === true)
        .filter((evento, indice, self) => { // Eliminamos duplicados por proyecciones
            return self.findIndex((eventoBusqueda) => eventoBusqueda.getDataId() === evento.getDataId()) === indice;
        });
    }

    public getServiciosAlterados(): IServicio[] {
        return this.getEventosAlterados()
        .filter((evento) => evento instanceof EventoSchedulerServicio)
            .map((evento) => evento.data);
    }

    public getAgrupacionesAlteradas(): IAgrupacionCompartidos[] {
        return this.getEventosAlterados()
        .filter((evento) => evento instanceof EventoSchedulerAgrupacion)
            .map((evento) => evento.data);
    }
}