// DHTMLX Scheduler
import "dhtmlx-scheduler";
import "lib_ext/dhtmlxscheduler/dhtmlxscheduler.js";
import "lib_ext/dhtmlxscheduler/ext/dhtmlxscheduler_timeline.js";
import "lib_ext/dhtmlxscheduler/ext/dhtmlxscheduler_treetimeline.js";
import "lib_ext/dhtmlxscheduler/ext/dhtmlxscheduler_daytimeline.js";
import "lib_ext/dhtmlxscheduler/ext/dhtmlxscheduler_container_autoresize.js";
import "lib_ext/dhtmlxscheduler/ext/dhtmlxscheduler_tooltip.js";
import "lib_ext/dhtmlxscheduler/ext/dhtmlxscheduler_minical.js";

import { EventoScheduler } from "../../helper/dhtmlxHelper/evento-scheduler.class";
import { RutaEventos } from "../../business-logic-layer/asignacion-automatica-servicios/ruta-servicios.class";
import { IServicio } from "../../entity/servicio.interface";
import { EstadoVistaAsig } from "../../estado-vista-asig.class";
import { IConductor } from "../../entity/conductor.interface";
import { DateUtils } from "../../utils/date.util";
import { IAgrupacionCompartidos } from "../../entity/agrupacion-compartidos.interface";
import { JsonUtils } from "../../utils/json.util";
import { EventoSchedulerServicio } from "./evento-scheduler-servicio.class";
import { EventoSchedulerAgrupacion } from "./evento-scheduler-agrupacion.class";

export class DhtlmxSchedulerHelper {

    public readonly KEY_SIN_ASIGNAR = 'NO_ASIGNADO';
    public readonly KEY_CONTAINER_SIN_ASIGNAR = 'CONT_SIN_ASIGNAR';
    public readonly KEY_CONTAINER_ASIGNADOS = 'CONT_ASIGNADOS';
    public readonly PREFIJO_KEY_CONTAINER_AREA_TRABAJO_RUTA = 'CONT_AREA_';
    public readonly PREFIJO_KEY_CONTAINER_AREA_TRABAJO_CONDUCTOR = 'CONT_AREA_';

    public readonly ID_CONTENEDOR_SCHEDULER;

    public readonly MIN_X_STEP = 5;
    public readonly MAX_X_STEP = 180;
    private xStep: number;
    private schedulerInstance: SchedulerStatic;
    private rutasEventos: RutaEventos[];

    private funTimelineScaleLabel: SchedulerCallback = (key: string, label: string, section) => label;

    constructor(idContenedorScheduler: string) {
        this.ID_CONTENEDOR_SCHEDULER = idContenedorScheduler;
        this.schedulerInstance = Scheduler.getSchedulerInstance();

        this.xStep = 30;

        this.setTemplateTooltip();
        this.setTemplateEventBar();
    }

    private setInitialConfigScheduler(
        dateMin: Date,
        dateMax: Date,
        dataScaleY: { key: string; label: string; }[]
    ) {
        this.schedulerInstance.config.xml_date = "%d-%m-%Y %H:%i";
        this.schedulerInstance.config.show_loading = true;

        this.schedulerInstance.config.details_on_create = false;
        this.schedulerInstance.config.details_on_dblclick = false;
        this.schedulerInstance.config.dblclick_create = false;
        this.schedulerInstance.config.edit_on_create = false;
        this.schedulerInstance.config.drag_resize = false;
        this.schedulerInstance.config.drag_create = false;

        this.schedulerInstance.xy.nav_height = 50;
        this.schedulerInstance.xy.bar_height = 30;
        this.schedulerInstance.xy.scale_height = 20;

        this.schedulerInstance.config.limit_view = true;
        this.schedulerInstance.config.limit_start = dateMin;
        this.schedulerInstance.config.limit_end = dateMax;

        this.schedulerInstance.tooltip.config.timeout_to_display = 400;
        this.schedulerInstance.tooltip.config.timeout_to_hide = 100;
        this.schedulerInstance.tooltip.config.delta_x = -2;
        this.schedulerInstance.tooltip.config.delta_y = 2;

        //Time range visibility
        var difMiliseconds = (dateMax.getTime() - dateMin.getTime());
        var difMinutes = difMiliseconds / 60000;
        let startMinutes = dateMin.getHours() * 60 + dateMin.getMinutes();

        let xStep = this.xStep;
        let xStart = Math.floor(startMinutes / xStep);
        let xSize = Math.ceil((difMinutes + startMinutes % xStep) / xStep);

        this.schedulerInstance.updateCollection("sections", dataScaleY);

        this.schedulerInstance.createTimelineView({
            name: 'timeline',
            x_unit: 'minute',
            x_date: '%H:%i',
            x_step: xStep,
            x_size: xSize,
            x_start: xStart,
            scrollable: true,
            column_width: 50,
            dy: 30,
            y_unit: this.schedulerInstance.serverList("sections"),
            y_property: 'section_id',
            render: 'tree',
            section_autoheight: false,
            second_scale: {
                x_unit: 'day',
                x_date: '%d/%m/%Y'
            }
        });
    }

    public closeTooltip() {
        this.schedulerInstance.tooltip.hide();
    }

    public getXStep(): number {
        return this.xStep;
    }

    public getCurrentMode(): string {
        return this.schedulerInstance.getState().mode;
    }

    public getRutas(): RutaEventos[] {
        return this.rutasEventos;
    }

    public getEvents(): EventoScheduler[] {
        return this.schedulerInstance.getEvents();
    }

    public getEvent(id: string): EventoScheduler {
        return this.schedulerInstance.getEvent(id);
    }

    public getEventsBySectionId(sectionId: string): EventoScheduler[] {
        return this.schedulerInstance.getEvents().filter((e: EventoScheduler) => e.section_id == sectionId);
    }

    public addEvents(events: EventoScheduler[]) {
        this.schedulerInstance.parse(events);
    }

    public setEvents(events: EventoScheduler[]) {
        this.schedulerInstance.clearAll();
        this.addEvents(events);
    }

    public deleteEventById(id: string) {
        this.schedulerInstance.deleteEvent(id);
    }

    public updateEvent(id: string) {
        this.schedulerInstance.updateEvent(id);
    }

    public updateView() {
        this.schedulerInstance.updateView();
    }

    private initScheduler(
        fechaMin: Date,
        fechaMax: Date,
        conductores: IConductor[],
        eventosSinRuta: EventoScheduler[],
        rutas?: RutaEventos[]
    ) {
        let eventosRutas: EventoScheduler[] = [];
        rutas.forEach(ruta => eventosRutas = eventosRutas.concat(ruta.getEventos()));
        let scaleX = eventosSinRuta.concat(eventosRutas);

        let scaleYRutas = rutas ? this.parseRutasScaleY(rutas) : [];
        scaleYRutas.unshift({ key: this.KEY_SIN_ASIGNAR, label: "SIN ASIGNAR" });
        let scaleYConductores = this.parseConductoresToScaleY(conductores);

        let scaleY = [
            { key: this.KEY_CONTAINER_SIN_ASIGNAR, label: 'SIN ASIGNAR', open: true, children: scaleYRutas },
            { key: this.KEY_CONTAINER_ASIGNADOS, label: 'ASIGNADOS', open: true, children: scaleYConductores }
        ];

        this.setInitialConfigScheduler(fechaMin, fechaMax, scaleY);

        // Comprobamos que el elemento HTML existe
        let contenedorExiste = $('#' + this.ID_CONTENEDOR_SCHEDULER).length > 0;
        if (contenedorExiste)
            this.schedulerInstance.init(this.ID_CONTENEDOR_SCHEDULER, this.schedulerInstance.config.limit_start, "timeline");

        this.setTemplateHeader(fechaMin, fechaMax);
        this.schedulerInstance.templates.timeline_scale_label = this.funTimelineScaleLabel;

        this.setEvents(scaleX);
    }

    /**
     * @param fun PARAMS = ( start: Date, end: Date, event: EventoScheduler ). RETURN = ( string )
     */
    public setTemplateEventClass(fun: SchedulerCallback) {
        this.schedulerInstance.templates.event_class = fun;
    }

    /**
     * @param fun PARAMS = ( id: string ). RETURN = ( boolean )
     */
    public setOnBeforeLightbox(fun: SchedulerCallback) {
        this.schedulerInstance.attachEvent("onBeforeLightbox", fun);
    }

    /**
     * @param fun PARAMS = ( id: string, mode: string, ev: MouseEvent ). RETURN = ( boolean )
     */
    public setOnBeforeDrag(fun: SchedulerCallback) {
        this.schedulerInstance.attachEvent("onBeforeDrag", fun);
    }

    /**
     * @param fun PARAMS = ( id: string, mode: string, ev: MouseEvent ). RETURN = ( void )
     */
    public setOnEventDrag(fun: SchedulerCallback) {
        this.schedulerInstance.attachEvent("onEventDrag", fun);
    }

    /**
     * @param fun PARAMS = ( id: string, mode: string, ev: MouseEvent ). RETURN = ( void )
     */
    public setOnDragEnd(fun: SchedulerCallback) {
        this.schedulerInstance.attachEvent("onDragEnd", fun);
    }

    /**
     * @param fun PARAMS = ( eventNow, ev: Event, is_new: boolean, eventOld ). RETURN = ( boolean )
     */
    public setOnBeforeEventChanged(fun: SchedulerCallback) {
        this.schedulerInstance.attachEvent("onBeforeEventChanged", fun);
    }

    /**
     * @param fun PARAMS = ( key: string, label: string, section ). RETURN = ( string )
     */
    public setClassScaleY(fun: SchedulerCallback) {
        this.schedulerInstance.templates.timeline_scaley_class = fun;
    }

    /**
     * @param fun PARAMS = ( key: string, label: string, section ). RETURN = ( string )
     */
    public setTimeLineScaleLabel(fun: SchedulerCallback) {
        this.funTimelineScaleLabel = fun;
    }

    /**
     * @param fun PARAMS = ( index: number, section, e: MouseEvent ). RETURN = ( void )
     */
    public setOnYScaleClick(fun: SchedulerCallback) {
        this.schedulerInstance.attachEvent("onYScaleClick", fun);
    }

    /**
     * @param fun PARAMS = ( eventId: number, e: MouseEvent ). RETURN = ( boolean )
     */
    public setOnContextMenu(fun: SchedulerCallback) {
        this.schedulerInstance.attachEvent("onContextMenu", fun);
    }

    /**
     * @param fun PARAMS = ( eventId: number ). RETURN = ( boolean )
     */
    public setOnBeforeTooltip(fun: SchedulerCallback) {
        this.schedulerInstance.attachEvent("onBeforeTooltip", fun);
    }

    public setXStep(xStep: number) {
        this.xStep = xStep;

        let dateMin = this.schedulerInstance.config.limit_start;
        let dateMax = this.schedulerInstance.config.limit_end;

        var difMiliseconds = (dateMax.getTime() - dateMin.getTime());
        var difMinutes = difMiliseconds / 60000;
        let startMinutes = dateMin.getHours() * 60 + dateMin.getMinutes();

        let xStart = Math.floor(startMinutes / xStep);
        let xSize = Math.ceil((difMinutes + startMinutes % xStep) / xStep);

        let view = this.schedulerInstance.getView();
        view.x_step = xStep;
        view.x_start = xStart;
        view.x_size = xSize;

        this.updateView();
    }

    public sumarMinutosXStep(min: number): boolean {
        let newXStep = this.xStep + min;
        let limiteAlcanzado = false;

        if (newXStep > this.MAX_X_STEP || newXStep < this.MIN_X_STEP)
            limiteAlcanzado = true;

        else {
            this.setXStep(newXStep);
            limiteAlcanzado = newXStep == this.MAX_X_STEP || newXStep == this.MIN_X_STEP;
        }

        return limiteAlcanzado;
    }

    private setTemplateHeader(fechaInicio: Date, fechaFin: Date) {
        let that = this;
        this.schedulerInstance.templates.timeline_date = function (date1, date2) {
            return DateUtils.parseDateToString(fechaInicio) + ' - ' + DateUtils.parseDateToString(fechaFin);
        };
    }

    private setTemplateTooltip() {
        this.schedulerInstance.templates.tooltip_text = function (start, end, event: EventoScheduler) {
            return event.getTemplateTooltip();
        };
    }

    private setTemplateEventBar() {
        let that = this;
        this.schedulerInstance.templates.event_bar_text = function (start: Date, end: Date, event: EventoScheduler) {
            let fechaLimiteSchedulerInicial = that.schedulerInstance.config.limit_start;
            let fechaLimiteSchedulerFinal = that.schedulerInstance.config.limit_end;

            return event.getTemplateBar(fechaLimiteSchedulerInicial, fechaLimiteSchedulerFinal);
        };
    }

    private parseConductoresToScaleY(conductores: IConductor[]) {
        conductores = conductores.sort((c1, c2) => {
            let nomCompl1 = c1.nombre + c1.apellido1 + (c1.apellido2 ? c1.apellido2 : '');
            let nomCompl2 = c2.nombre + c2.apellido1 + (c2.apellido2 ? c2.apellido2 : '');
            return nomCompl1.localeCompare(nomCompl2);
        });

        let scaleY: { key: string, label: string, open?: boolean, children?: any[] }[] = [];
        conductores.forEach((conductor) => {
            let nombreCompleto = conductor.nombre + " " + conductor.apellido1;
            if (conductor.apellido2)
                nombreCompleto += " " + conductor.apellido2;

            let sectionConductor = { key: conductor.id.toString(), label: nombreCompleto };

            let areaTrabajo = conductor.areaTrabajo;
            let containerKey = this.PREFIJO_KEY_CONTAINER_AREA_TRABAJO_CONDUCTOR + (areaTrabajo ? areaTrabajo.nombre : '');
            let containerLabel = areaTrabajo ? areaTrabajo.nombre.toUpperCase() : 'SIN ÁREA DE TRABAJO';

            let sectionIndex = scaleY.findIndex((section) => section.key === containerKey);

            if (sectionIndex > -1) {
                scaleY[sectionIndex].children.push(sectionConductor);
            }
            else {
                scaleY.push({
                    key: containerKey,
                    label: containerLabel,
                    open: true,
                    children: [sectionConductor]
                });
            }
        });

        scaleY.sort((section1, section2) => section1.key.localeCompare(section2.key));

        return scaleY;
    }

    private parseServicioToScaleX(servicio: IServicio) {
        let conductoresAsignados = servicio.idConductores;
        let section_id = conductoresAsignados.length > 0 ?
            conductoresAsignados[0].toString() :
            this.KEY_SIN_ASIGNAR;

        return new EventoSchedulerServicio(
            servicio,
            section_id,
            "Servicio con ID: " + servicio.id
        );
    }

    private parseAgrupacionToScaleX(agrupacion: IAgrupacionCompartidos) {
        let conductoresAsignados = agrupacion.idConductores;
        let section_id = conductoresAsignados.length > 0 ?
            conductoresAsignados[0] :
            this.KEY_SIN_ASIGNAR;

        return new EventoSchedulerAgrupacion(
            agrupacion,
            section_id.toString(),
            "Agrupación con ID: " + agrupacion.id
        );
    }

    private parseRutasScaleY(rutas: RutaEventos[]): any[] {
        let scaleY: { key: string, label: string, open?: boolean, children?: any[] }[] = [];

        rutas.forEach((ruta, indiceRuta) => {
            let sectionRuta = {
                key: 'R' + ruta.getId(),
                label: 'R' + indiceRuta
            };

            let areaTrabajo = ruta.getAreaTrabajo();
            let containerKey = this.PREFIJO_KEY_CONTAINER_AREA_TRABAJO_RUTA;
            let containerLabel = 'SIN ÁREA DE TRABAJO';

            if (areaTrabajo) {
                containerKey += areaTrabajo.nombre;
                containerLabel = areaTrabajo.nombre.toUpperCase();
            }

            let sectionIndex = scaleY.findIndex((section) => section.key === containerKey);

            if (sectionIndex > -1) {
                scaleY[sectionIndex].children.push(sectionRuta);
            }
            else {
                scaleY.push({
                    key: containerKey,
                    label: containerLabel,
                    open: true,
                    children: [sectionRuta]
                });
            }
        })

        scaleY.sort((section1, section2) => section1.key.localeCompare(section2.key));
        return scaleY;
    }

    public getDateToStringFormatter_(pattern: string): any {
        return this.schedulerInstance.date.date_to_str(pattern);
    }

    public setSectionsConductores(conductores: IConductor[]) {
        let sections = [...this.schedulerInstance.serverList("sections")];
        let seccionesConductores = this.parseConductoresToScaleY(conductores);

        sections.find((s) => s.key == this.KEY_CONTAINER_ASIGNADOS).children = seccionesConductores;

        this.schedulerInstance.updateCollection("sections", sections);
    }

    public setSectionesRutas(rutas: RutaEventos[]) {
        let sections = [...this.schedulerInstance.serverList("sections")];
        let seccionesRutas = this.parseRutasScaleY(rutas);
        seccionesRutas.unshift({ key: this.KEY_SIN_ASIGNAR, label: "SIN ASIGNAR" });

        sections.find((s) => s.key == this.KEY_CONTAINER_SIN_ASIGNAR).children = seccionesRutas;

        this.schedulerInstance.updateCollection("sections", sections);
    }

    public changeSection(eventsId: string[], newSectionId: string) {
        let events = this.getEvents();
        events.forEach((e) => {
            if (eventsId.includes(e.id))
                e.section_id = newSectionId;
        });

        this.setEvents(events);
    }

    restablecerScheduler(estadoVista: EstadoVistaAsig) {
        let scaleYRutas = estadoVista.rutas ? this.parseRutasScaleY(estadoVista.rutas) : [];
        scaleYRutas.unshift({ key: this.KEY_SIN_ASIGNAR, label: "SIN ASIGNAR" });
        let scaleYConductores = this.parseConductoresToScaleY(estadoVista.conductores);

        let scaleY = [
            { key: this.KEY_CONTAINER_SIN_ASIGNAR, label: 'SIN ASIGNAR', open: true, children: scaleYRutas },
            { key: this.KEY_CONTAINER_ASIGNADOS, label: 'ASIGNADOS', open: true, children: scaleYConductores }
        ];

        this.schedulerInstance.updateCollection("sections", scaleY);
        this.setEvents(estadoVista.eventos);
    }

    eventoFueraDeRangoInicial(event: EventoScheduler): boolean {
        let fechaLimiteSchedulerInicial = this.schedulerInstance.config.limit_start;
        return event.getSegundosInicialesFueraDeRango(fechaLimiteSchedulerInicial) > 0;
    }

    eventoFueraDeRangoFinal(event: EventoScheduler): boolean {
        let fechaLimiteSchedulerFinal = this.schedulerInstance.config.limit_end;
        return event.getSegundosFinalesFueraDeRango(fechaLimiteSchedulerFinal) > 0;
    }

    public iniciarActualizarScheduler(
        fechaHoraInicio: Date,
        fechaHoraFin: Date,
        servicios: IServicio[],
        agrupaciones: IAgrupacionCompartidos[],
        conductores: IConductor[],
        generarRutas: boolean = false,
        duracionMaxMinutosRuta: number = 12
    ) {
        return new Promise<void>((resolve, reject) => {
            let that = this;

            let eventos: EventoScheduler[] = [];
            let eventosSinAsignar: EventoScheduler[] = [];
            let eventosAsignadosAConductor: EventoScheduler[] = [];

            //Convertir servicios y agrupaciones en Eventos
            eventos = eventos.concat(servicios.map(servicio => this.parseServicioToScaleX(servicio)));
            eventos = eventos.concat(agrupaciones.map(agrupacion => this.parseAgrupacionToScaleX(agrupacion)));
            // TODO eventos = eventos.concat(agrupaciones.map(agrupacion => this.parseAgrupacionCompartidosToScaleX(agrupacion)));

            //Filtrar eventos sin conductores
            eventosSinAsignar = eventos.filter(evento => evento.getIdConductores().length === 0);
            eventosAsignadosAConductor = eventos.filter(evento => evento.getIdConductores().length > 0);

            if (generarRutas) {
                let rutasGeneradas: RutaEventos[] = [];
                let generadorRutasWorker = new Worker('src/app/core/workers/generador-rutas-servicios.worker.ts', { type: "module" });
                
                generadorRutasWorker.onmessage = function (oEvent) {
                    let rutasGeneradasSinFormato = JsonUtils.fromJson(oEvent.data) as any[]

                    // Formateamos rutas y referenciamos eventos
                    rutasGeneradas = rutasGeneradasSinFormato.map((object) =>  {
                        let ruta = RutaEventos.fromJsonObject(object);
                        ruta.setEventos(ruta.getEventos().map(eventoRuta => {
                            let eventoReferenciado = eventos.find(evento => evento.id === eventoRuta.id);
                            eventoReferenciado.section_id = eventoRuta.section_id
                            return eventoReferenciado;
                        }));
                        return ruta;
                    });
                    that.rutasEventos = rutasGeneradas;

                    that.initScheduler(fechaHoraInicio, fechaHoraFin, conductores, eventosAsignadosAConductor, rutasGeneradas);
                    resolve();
                };

                generadorRutasWorker.postMessage(JsonUtils.toJson({ eventos: eventosSinAsignar, duracionMaxMinutosRuta: duracionMaxMinutosRuta }));
            }
            else {
                that.initScheduler(fechaHoraInicio, fechaHoraFin, conductores, eventos, []);
                resolve();
            }
        });
    }
}
