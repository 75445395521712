import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';

@Component({
  selector: 'evtc-custom-datepicker',
  templateUrl: './custom-datepicker.component.html',
  styleUrls: ['./custom-datepicker.component.scss']
})
export class CustomDatepickerComponent implements OnInit {

  @Input()
  public label: string = '';
  @Input()
  public selectedDate: Date = null;
  @Output()
  public onSelectionChanged = new EventEmitter<any>();

  @Output() selectedDateChange = new EventEmitter<Date>();
  
  constructor(private localeService: BsLocaleService) { }

  ngOnInit(): void {
    this.localeService.use('es'); // Fechas en formato español para el datepicker
  }

  public ngModelChange() {
    this.selectedDateChange.emit(this.selectedDate);
    this.onSelectionChanged.emit();
  }

}
