export abstract class LocationUtils {
    /**
   * Devuelve la distancia en kilomegtros entre dos puntos dados por su latitud y longitud
   *
   * @param latitud1 Latitud del punto 1
   * @param longitud1 Longitud del punto 1
   * @param latitud2 Latitud del punto 2
   * @param longitud2 Longitud del punto 2
   *
   * @returns Distancia en kilometros
   *
   **/
  public static getKilometrosEntreCoordenadas(latitud1: number, longitud1: number, latitud2: number, longitud2: number): number
  {
    let rad = (grados) => grados * Math.PI / 180;
    let radioTierraKm = 6378.137;
    let diferenciaLatitud = rad(latitud2 - latitud1);
    let diferenciaLongitud = rad(longitud2 - longitud1);

    let a = Math.sin(diferenciaLatitud / 2) * Math.sin(diferenciaLatitud / 2) + Math.cos(rad(latitud1)) * Math.cos(rad(latitud2)) * Math.sin(diferenciaLongitud / 2) * Math.sin(diferenciaLongitud / 2);
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
    let d = radioTierraKm * c;

    return d;
  }
}