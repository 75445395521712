export abstract class UserInterfaceUtils {

    private static readonly MIN_BLRILLO_COLOR_FONDO = 150;

    public static esColorHexFondoClaro(hexadecimal: string): boolean {
        if(hexadecimal.startsWith('#'))
            hexadecimal = hexadecimal.substr(1)

        return this.getBrilloColor(hexadecimal) > UserInterfaceUtils.MIN_BLRILLO_COLOR_FONDO;
    }

    /**
     * OBTIENE EL VALOR DE LA LUMINOSIDAD DE UN COLOR HEXADECIMAL.<br/>
     * RANGO DE VALORES: 0 (MUY OSCURO) - 255 (MUY ILUMINADO)
     */
    private static getBrilloColor(hexadecimal: string) {
        //Converting to rgb and multiplying luminance
        let r = 0.2126 * parseInt(hexadecimal.substr(0, 2), 16);
        let g = 0.7152 * parseInt(hexadecimal.substr(2, 2), 16);
        let b = 0.0722 * parseInt(hexadecimal.substr(4, 2), 16);

        return r + g + b;
    }
}