// Entity
import { IAgrupacionCompartidos } from '../entity/agrupacion-compartidos.interface';
import { IConductor } from '../entity/conductor.interface';
import { IConfiguracion } from '../entity/configuracion.interface';
import { IServicio } from '../entity/servicio.interface';
import { IVehiculo } from '../entity/vehiculo.interface';
import { ServicioBll } from './servicio.bll';

export class AgrupacionCompartidosBll {

  constructor() { }

  public static getServicios(agrupacion: IAgrupacionCompartidos): IServicio[] {
    return agrupacion.datosAgrupacionesCompartidos.map(datosAgrupacion => datosAgrupacion.servicio)
  }

  public static esCompatibleConConductor(agrupacion: IAgrupacionCompartidos, conductor: IConductor): boolean {
    return this.getServicios(agrupacion).every(servicio => ServicioBll.esCompatibleConConductor(servicio, conductor));
  }

  public static esCompatibleConVehiculo(agrupacion: IAgrupacionCompartidos, vehiculo: IVehiculo): boolean {
    return this.getServicios(agrupacion).every(servicio => ServicioBll.esCompatibleConVehiculo(servicio, vehiculo));
  }

  public static formatearAgrupacionRecibidaDeAPI(agrupacion: IAgrupacionCompartidos, configuracion: IConfiguracion) {
    //Ordenamos por orden de parada
    agrupacion.datosAgrupacionesCompartidos.sort((datosAgrupacion1, datosAgrupacion2) => datosAgrupacion1.ordenParada - datosAgrupacion2.ordenParada);
    agrupacion.zonaCompartidos.textosAsociadosZonaCompartidos.sort((parada1, parada2) => parada1.ordenDentroZonaCompartidos - parada2.ordenDentroZonaCompartidos);
    //Formateamos cada uno de los servicios que la componen
    this.getServicios(agrupacion).forEach(servicio => ServicioBll.formatearServicioRecibidoDeAPI(servicio, configuracion));

    this.calcularDatosAgrupacion(agrupacion);

    return agrupacion;
  }

  public static calcularDatosAgrupacion(agrupacion: IAgrupacionCompartidos) {
    let serviciosAgrupacion = this.getServicios(agrupacion);

    if (serviciosAgrupacion.length > 0) {
      let primerServicio = serviciosAgrupacion[0];
      let ultimoServicio = serviciosAgrupacion[serviciosAgrupacion.length - 1];
      let duracionSegundosTotal = 0;
      let distanciaMetrosTotal = 0;
      let numeroAdultosTotal = 0;
      let numeroNinyosTotal = 0;
      let numeroBebesTotal = 0;

      serviciosAgrupacion.forEach(servicio => {
        duracionSegundosTotal += servicio.duracionSegundos;
        distanciaMetrosTotal += servicio.distanciaMetros;
        numeroAdultosTotal += servicio.numeroAdultos;
        numeroNinyosTotal += servicio.numeroNinyos;
        numeroBebesTotal += servicio.numeroBebes;
      });

      let vehiculoAgrupacion = primerServicio.vehiculo;
      let idConductoresAgrupacion = primerServicio.idConductores;

      let esSalida = ServicioBll.TIPO_NO_SALIDA_LLEGADA;
      let numeroVuelo = null;

      if (primerServicio.esSalida === ServicioBll.TIPO_ES_LLEGADA) {
        esSalida = primerServicio.esSalida;
        numeroVuelo = primerServicio.numVuelo;
      }
      else if (ultimoServicio.esSalida === ServicioBll.TIPO_ES_SALIDA) {
        esSalida = ultimoServicio.esSalida;
        numeroVuelo = ultimoServicio.numVuelo;
      }

      agrupacion.fechaInicio = primerServicio.fechaInicio;
      agrupacion.fechaFin = ultimoServicio.fechaFin;
      //agrupacion.duracionSegundos = duracionSegundosTotal;
      agrupacion.origen = primerServicio.origen;
      agrupacion.destino = ultimoServicio.destino;
      //agrupacion.distanciaMetros = distanciaMetrosTotal;
      agrupacion.tipoServicio = primerServicio.tipoServicio;
      agrupacion.areaTrabajo = primerServicio.areaTrabajo;
      agrupacion.vehiculo = vehiculoAgrupacion;
      agrupacion.idConductores = idConductoresAgrupacion;
      agrupacion.latitudOrigen = primerServicio.latitudOrigen;
      agrupacion.longitudOrigen = primerServicio.longitudOrigen;
      agrupacion.latitudDestino = ultimoServicio.latitudDestino;
      agrupacion.longitudDestino = ultimoServicio.longitudDestino;
      agrupacion.numeroAdultos = numeroAdultosTotal;
      agrupacion.numeroNinyos = numeroNinyosTotal;
      agrupacion.numeroBebes = numeroBebesTotal;
      agrupacion.esSalida = esSalida;
      agrupacion.numVuelo = numeroVuelo;

      agrupacion.dateFechaInicio = primerServicio.dateFechaInicio;
      agrupacion.dateFechaFin = ultimoServicio.dateFechaFin;
      agrupacion.dateFechaFinConMargen = ultimoServicio.dateFechaFinConMargen;

      agrupacion.margenSegundosInicial = primerServicio.margenSegundosInicial;
      agrupacion.margenSegundosFinal = ultimoServicio.margenSegundosFinal;

      this.calcularDuracionYDistancia(agrupacion);
    }

    return agrupacion;
  }

  public static calcularDuracionYDistancia(agrupacion: IAgrupacionCompartidos) {
    let zonaCompartidos = agrupacion.zonaCompartidos;
    let serviciosAgrupacion = this.getServicios(agrupacion);

    let duracionSegundosTotal = 0;
    let distanciaMetrosTotal = 0;

    if (zonaCompartidos && serviciosAgrupacion.length > 0) {
      // Partimos con la base de la duración y distancia del primer servicio
      duracionSegundosTotal = serviciosAgrupacion[0].duracionSegundos;
      distanciaMetrosTotal = serviciosAgrupacion[0].distanciaMetros;

      let paradasSolicitadas = serviciosAgrupacion.map(servicio => servicio.textoAsociadoZonaCompartidos);
      let primeraParadaSolicitada = paradasSolicitadas[0];
      let ultimaParadaSolicitada = paradasSolicitadas[paradasSolicitadas.length - 1];

      let mayorOrdenZona = 0;
      let menorOrdenZona = 0;
      if (ultimaParadaSolicitada.ordenDentroZonaCompartidos >= primeraParadaSolicitada.ordenDentroZonaCompartidos) {
        mayorOrdenZona = ultimaParadaSolicitada.ordenDentroZonaCompartidos;
        menorOrdenZona = primeraParadaSolicitada.ordenDentroZonaCompartidos;
      }
      else {
        mayorOrdenZona = primeraParadaSolicitada.ordenDentroZonaCompartidos;
        menorOrdenZona = ultimaParadaSolicitada.ordenDentroZonaCompartidos;
      }

      let paradasIntermedias = zonaCompartidos.textosAsociadosZonaCompartidos.filter(parada =>
        parada.ordenDentroZonaCompartidos > menorOrdenZona
        && parada.ordenDentroZonaCompartidos < mayorOrdenZona
      );

      paradasSolicitadas.forEach(parada => {
        if (parada.id != primeraParadaSolicitada.id) {
          duracionSegundosTotal += parada.tiempoParadaSegundos;
        }

        if (parada.id != ultimaParadaSolicitada.id) {
          distanciaMetrosTotal += parada.distanciaMetrosSiguienteParada;
          duracionSegundosTotal += parada.duracionSegundosSiguienteParada;
        }
      });

      paradasIntermedias.forEach(parada => {
        distanciaMetrosTotal += parada.distanciaMetrosSiguienteParada;
        duracionSegundosTotal += parada.duracionSegundosSiguienteParada;
      });
    }

    agrupacion.duracionSegundos = duracionSegundosTotal;
    agrupacion.distanciaMetros = distanciaMetrosTotal;

    return agrupacion;
  }

  public static getIconosDatos(agrupacion: IAgrupacionCompartidos, formatoLargo: boolean = true): string[] {
    let iconos: string[] = [];

    if (formatoLargo && agrupacion.esSalida === ServicioBll.TIPO_ES_LLEGADA)
      iconos.push("<i class='fas fa-plane-arrival icono-dato-servicio'></i>");
    if (formatoLargo && agrupacion.esSalida === ServicioBll.TIPO_ES_SALIDA)
      iconos.push("<i class='fas fa-plane-departure icono-dato-servicio'></i>");

    if (formatoLargo)
      iconos.push("<i class='fas fa-object-group icono-dato-servicio'></i>");

    if (formatoLargo && agrupacion.idConductores.length > 0)
      iconos.push("<i class='fas fa-user icono-dato-servicio'></i>");
    else if (formatoLargo)
      iconos.push("<i class='fas fa-user icono-dato-servicio prohibido'></i>");

    if (agrupacion.vehiculo)
      iconos.push("<i class='fas fa-car icono-dato-servicio'></i>");
    else if (formatoLargo)
      iconos.push("<i class='fas fa-car icono-dato-servicio prohibido'></i>");

    if (!this.tienenDireccionesGoogleMaps(agrupacion))
      iconos.push("<i class='fab fa-google icono-dato-servicio prohibido'></i>");
    else if (formatoLargo)
      iconos.push("<i class='fab fa-google icono-dato-servicio'></i>");

    return iconos;
  }

  public static tienenDireccionesGoogleMaps(agrupacion: IAgrupacionCompartidos) {
    return agrupacion.latitudOrigen && agrupacion.longitudOrigen && agrupacion.latitudDestino && agrupacion.longitudDestino;
  }

  public static getTipo(agrupacion: IAgrupacionCompartidos): string {
    let tipo = '';
    let servicios = this.getServicios(agrupacion);

    if (servicios.length > 0)
      tipo = servicios[0].tipo;

    return tipo;
  }

  public static getNombreTipo(agrupacion: IAgrupacionCompartidos): string {
    let tipo = '';
    let servicios = this.getServicios(agrupacion);

    if (servicios.length > 0)
      tipo = ServicioBll.getNombreTipo(servicios[0]);

    return tipo;
  }

  public static getPrefijoTipo(agrupacion: IAgrupacionCompartidos): string {
    let prefijo = '';
    let servicios = this.getServicios(agrupacion);

    if (servicios.length > 0)
      prefijo = ServicioBll.getPrefijoTipo(servicios[0]);

    return prefijo;
  }

  public static seHaModificado(agrupacionOld: IAgrupacionCompartidos, agrupacionNew: IAgrupacionCompartidos) {
    return this.getMensajesCambios(agrupacionOld, agrupacionNew).length > 0;
  }

  public static getMensajesCambios(agrupacionOld: IAgrupacionCompartidos, agrupacionNew: IAgrupacionCompartidos): string[] {
    let cambios: string[] = [];
    // Comprobamos campos editables desde la vista de edición
    if (agrupacionOld.dateFechaInicio.getTime() !== agrupacionNew.dateFechaInicio.getTime())
      cambios.push('Cambio Fecha inicio');
    if (agrupacionOld.origen !== agrupacionNew.origen
      || agrupacionOld.latitudOrigen !== agrupacionNew.latitudOrigen
      || agrupacionOld.longitudOrigen !== agrupacionNew.longitudOrigen)
      cambios.push('Cambio Origen');
    if (agrupacionOld.destino !== agrupacionNew.destino
      || agrupacionOld.latitudDestino !== agrupacionNew.latitudDestino
      || agrupacionOld.longitudDestino !== agrupacionNew.longitudDestino)
      cambios.push('Cambio Destino');

    // Comprobamos asignaciones de vehiculo y conductor
    if (agrupacionOld.vehiculo?.id !== agrupacionNew.vehiculo?.id) {
      if (!agrupacionOld.vehiculo)
        cambios.push('Asignación Vehículo');
      else if (!agrupacionNew.vehiculo)
        cambios.push('Eliminación Vehículo');
      else
        cambios.push('Reasignación Vehículo');
    }
    if (agrupacionOld.idConductores.length !== agrupacionNew.idConductores.length
      || agrupacionOld.idConductores.some((idConductorOld) => agrupacionNew.idConductores.some((idConductorNew) => idConductorNew !== idConductorOld))) {
      if (agrupacionOld.idConductores.length === 0)
        cambios.push('Asignación Conductor');
      else if (agrupacionNew.idConductores.length === 0)
        cambios.push('Eliminación Conductor');
      else
        cambios.push('Reasignación Conductor');
    }

    return cambios;
  }
}
