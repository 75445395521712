import { MyPdfMake } from "./MyPdfMake";
import { MyReportGroup } from "./MyReportGroup";

export class MyReport2 extends MyPdfMake {
    _textoIzquierdaHeader = '';
    _textoDerechaHeader = '';
    _textoIzquierdaFooter = '';
    _textoDerechaFooter = null;
    _grupos: MyReportGroup[] = [];
    _titulo = [];
    _subtitulos = [];
    _bordesLineasContenido = null;

    private setBordesLineasContenido(bordes) {
        this._bordesLineasContenido = bordes;
    }

    protected setTextoIzquierdaHeader(texto) {
        this._textoIzquierdaHeader = texto;
    }

    private setTextoDerechaHeader(texto) {
        this._textoDerechaHeader = texto;
    }

    protected setTextoIzquierdaFooter(texto) {
        this._textoIzquierdaFooter = texto;
    }

    private setTextoDerechaFooter(texto) {
        this._textoDerechaFooter = texto;
    }

    private setTituloInforme(texto) {
        let alignment = 'center';
        let margin = [0, 10, 0, 10];
        let fontSize = 20;
        let bold = true;
        this._titulo = this.getTexto(texto, margin, fontSize, bold, alignment);
    }

    private addSubtituloInforme(texto) {
        let alignment = 'left';
        let margin = [15, 5, 15, 5];
        let fontSize = 12;
        let bold = false;
        let subtitulo = this.getTexto(texto, margin, fontSize, bold, alignment);

        this._subtitulos.push(subtitulo);
    }

    public addGrupo(grupo: MyReportGroup) {
        this._grupos.push(grupo);
    }

    protected print() {
        let contenido = [];

        if (this._titulo != null)
            contenido.push(this._titulo);

        if (this._subtitulos.length > 0)
            contenido.push(this._subtitulos);

        for (let i = 0; i < this._grupos.length; i++)
            contenido.push(this._grupos[i].getContenidoGrupo());

        let pageMargins = [0, 18, 0, 18];
        let pageOrientation = 'landscape';
        let fontSizeHeader = 8;
        let fontSizeFooter = 8;
        let marginHeader = [10, 0];
        let marginFooter = [10, 0];

        this.createPDF(
            pageMargins,
            pageOrientation,
            fontSizeHeader, marginHeader, this._textoIzquierdaHeader, this._textoDerechaHeader,
            fontSizeFooter, marginFooter, this._textoIzquierdaFooter, this._textoDerechaFooter,
            contenido
        );
    };

    protected getNombreEmpresaDelLogo() {
        return $('.nombre-empresa').text();
    };
}
