import * as pdfMake from "node_modules/pdfmake/build/pdfmake.js";
import * as pdfFonts from 'node_modules/pdfmake/build/vfs_fonts.js';

(<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;

export class MyPdfMake {
    
    protected getTabla(anchosColumnas, contenido, margin?: number | null, fontSize?: number | null, bordesLineasContenido?: any[], hayFilaTotales?: boolean) {
        return {
            margin: margin != null ? margin : [15, 0, 15, 10],
            fontSize: fontSize != null ? fontSize : 8,
            table: {
                headerRows: 1,
                dontBreakRows: true,
                widths: anchosColumnas,
                body: contenido
            },
            //Estilo de los bordes
            layout: {
                hLineWidth: (i, node) => {
                    if (i === 0)
                        return 0;

                    if (i === 1)
                        return 1;

                    if (bordesLineasContenido != null && bordesLineasContenido.length > i - 2)
                        return bordesLineasContenido[i - 2].width;

                    if (i === node.table.body.length - 1)
                        return 1;

                    if (i === node.table.body.length)
                        return 0;

                    return 1;
                },
                vLineWidth: (i, node) => 0,
                hLineColor: (i, node) => {
                    if (i === 1)
                        return 'black';

                    if (bordesLineasContenido != null && bordesLineasContenido.length > i - 2)
                        return bordesLineasContenido[i - 2].color;

                    if (i === node.table.body.length - 1) {
                        if (hayFilaTotales)
                            return 'black';
                        else
                            return 'lightgray';
                    }

                    return 'lightgray';
                },
                vLineColor: (i, node) => 'white'
            }
        };
    }

    protected getTexto(
        texto: string, margin?: number | number[],
        fontSize?: number, bold?: boolean,
        alignment?: string, id?: number,
        pageBreak?: 'before' | 'after', colSpan?: number
    ) {
        let objTexto: any = {
            text: texto,
            margin: margin != null ? margin : [0, 0, 0, 0],
            fontSize: fontSize ? fontSize : 15,
            bold: bold != null ? bold : true,
            alignment: alignment != null ? alignment : 'left'
        };

        if (colSpan != null)
            objTexto.colSpan = colSpan;

        if (id != null)
            objTexto.id = id;

        if (pageBreak != null)
            objTexto.pageBreak = pageBreak;

        return objTexto;
    }

    private getHeader(fontSize, margin, textoIzquierdaHeader, textoDerechaHeader) {
        return {
            fontSize: fontSize,
            margin: margin,
            table: {
                widths: [404, 404],
                body: [
                    [
                        {
                            alignment: 'left',
                            text: textoIzquierdaHeader
                        },
                        {
                            alignment: 'right',
                            text: textoDerechaHeader
                        }
                    ]
                ],
            },
            //Estilo de los bordes
            layout: {
                hLineWidth: (i, node) => (i > 0) ? 1 : 0,
                vLineWidth: (i, node) => 0,
                hLineColor: (i, node) => 'black',
                vLineColor: (i, node) => 'white'
            }
        };
    }

    private getFooter(currentPage, pageCount, fontSize, margin, textoIzquierdaFooter, textoDerechaFooter) {

        return {
            fontSize: fontSize,
            margin: margin,
            table: {
                widths: [404, 404],
                body: [
                    [
                        {
                            alignment: 'left',
                            text: textoIzquierdaFooter,
                        },
                        {
                            alignment: 'right',
                            text: (textoDerechaFooter != null ? textoDerechaFooter : currentPage.toString() + ' de ' + pageCount)
                        }
                    ]
                ],
            },
            //Estilo de los bordes
            layout: {
                hLineWidth: (i, node) => (i === 0 || i === node.table.body.length) ? 1 : 0,
                vLineWidth: (i, node) => 0,
                hLineColor: (i, node) => (i === 0 || i === node.table.widths.length) ? 0 : 'white',
                vLineColor: (i, node) => 'white'
            }
        };
    }

    protected createPDF(
        pageMargins,
        pageOrientation,
        fontSizeHeader, marginHeader, textoIzquierdaHeader, textoDerechaHeader,
        fontSizeFooter, marginFooter, textoIzquierdaFooter, textoDerechaFooter,
        contenido
    ) {
        let docDefinition = {
            pageMargins: pageMargins,
            pageOrientation: pageOrientation,
            header: (currentPage, pageCount) => this.getHeader(fontSizeHeader, marginHeader, textoIzquierdaHeader, textoDerechaHeader),
            footer: (currentPage, pageCount) => this.getFooter(currentPage, pageCount, fontSizeFooter, marginFooter, textoIzquierdaFooter, textoDerechaFooter),
            content: contenido
        };

        pdfMake.createPdf(docDefinition).print();
    }
}
