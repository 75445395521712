// Entity
import { EventoSchedulerAgrupacion } from '../helper/dhtmlxHelper/evento-scheduler-agrupacion.class';
import { EventoSchedulerServicio } from '../helper/dhtmlxHelper/evento-scheduler-servicio.class';
import { EventoScheduler, TipoDataEventoScheduler } from '../helper/dhtmlxHelper/evento-scheduler.class';

export abstract class EventoSchedulerBll {

  public static fromJsonObject(evento: EventoScheduler): EventoScheduler {
    let objetoFormateado: EventoScheduler = null;

    switch (evento.TIPO_DATA) {
      case TipoDataEventoScheduler.SERVICIO:
        objetoFormateado = new EventoSchedulerServicio(evento.data, evento.section_id, evento.text);
        break;
      case TipoDataEventoScheduler.AGRUPACION:
        objetoFormateado = new EventoSchedulerAgrupacion(evento.data, evento.section_id, evento.text);
        break;
    }

    return Object.assign(
      objetoFormateado,
      evento
    );
  }
  
}