import { AgrupacionCompartidosBll } from "../business-logic-layer/agrupacion-compartidos.bll";
import { RutaEventos } from "../business-logic-layer/asignacion-automatica-servicios/ruta-servicios.class";
import { IAgrupacionCompartidos } from "../entity/agrupacion-compartidos.interface";
import { ICliente } from "../entity/cliente.interface";
import { IConductor } from "../entity/conductor.interface";
import { IServicio } from "../entity/servicio.interface";
import { IViajero } from "../entity/viajero.interface";
import { EventoSchedulerAgrupacion } from "../helper/dhtmlxHelper/evento-scheduler-agrupacion.class";
import { EventoSchedulerServicio } from "../helper/dhtmlxHelper/evento-scheduler-servicio.class";
import { EventoScheduler } from "../helper/dhtmlxHelper/evento-scheduler.class";
import { DateUtils } from "../utils/date.util";
import { StringUtils } from "../utils/string.util";
import { MyReport2 } from "./MyReport2";
import { MyReportGroup } from "./MyReportGroup";

export class InfRutasServicios extends MyReport2 {
    private getNombreViajeroFormateado(viajero: IViajero) {
        let nombreViajeroFormateado = '';

        if (viajero) {
            nombreViajeroFormateado += viajero.nombre
                + (viajero.apellido1 ? ' ' + viajero.apellido1 : '')
                + (viajero.apellido2 ? ' ' + viajero.apellido2 : '');

            if (viajero.telefono)
                nombreViajeroFormateado += '\nTfno: ' + viajero.telefono;
        }

        return nombreViajeroFormateado;
    }

    private getNombreConductorFormateado(conductor: IConductor) {
        return conductor ?
            conductor.codigo
            + ' - ' + conductor.nombre
            + (conductor.apellido1 ? ' ' + conductor.apellido1 : '')
            + (conductor.apellido2 ? ' ' + conductor.apellido2 : '') :
            '';
    }

    private getTrayectoFormateadoServicio(servicio: IServicio) {
        return servicio.nombreTrayecto + (servicio.tipoServicio? '\n' + servicio.tipoServicio.nombre: '');
    }

    private getTrayectoFormateadoAgrupacion(agrupacion: IAgrupacionCompartidos) {
        let zonaCompartidos = agrupacion.zonaCompartidos;
        let tipoServicio = agrupacion.tipoServicio;
        let trayectoFormateado = 'AGRUPACIÓN';

        if(zonaCompartidos)
            trayectoFormateado += ' - ' + zonaCompartidos.nombre;
        if(tipoServicio)
            trayectoFormateado += '\n' + tipoServicio.nombre;

        return trayectoFormateado;
    }

    private getNumeroPasajerosFormateadoServicio(servicio: IServicio) {
        return servicio.numeroAdultos + servicio.numeroNinyos + servicio.numeroBebes + '\n' + servicio.numeroAdultos + 'A-' + servicio.numeroNinyos + 'N-' + servicio.numeroBebes + 'B';
    }

    private getNumeroPasajerosFormateadoAgrupacion(agrupacion: IAgrupacionCompartidos) {
        return agrupacion.numeroAdultos + agrupacion.numeroNinyos + agrupacion.numeroBebes + '\n' + agrupacion.numeroAdultos + 'A-' + agrupacion.numeroNinyos + 'N-' + agrupacion.numeroBebes + 'B';
    }

    private getClienteFormateado(cliente: ICliente) {
        return cliente ?
            cliente.nombre + '\n' + cliente.nifCif :
            '';
    }

    private getObservacionesFormateadas(descripcion) {
        return StringUtils.cleanHtml(descripcion);
    }

    private getObservacionesAgrupacion(agrupacion: IAgrupacionCompartidos) {
        let servicios = AgrupacionCompartidosBll.getServicios(agrupacion);
        let observaciones = "COMPARTIDOS";
        servicios.forEach(servicio => observaciones += '\n(' + servicio.id + ")")
        return observaciones;
    }

    private getFilaInforme(evento: EventoScheduler) {
        let fila = [];

        if(evento instanceof EventoSchedulerServicio)
            fila = this.getFilaInformeServicio(evento.data);
        if(evento instanceof EventoSchedulerAgrupacion)
            fila = this.getFilaInformeAgrupacion(evento.data);

        return fila;
    }

    private getFilaInformeServicio(servicio: IServicio) {
        return [
            servicio.id,
            DateUtils.parseDateToString(servicio.dateFechaInicio, 'H:mm'),
            this.getTrayectoFormateadoServicio(servicio),
            servicio.cliente? this.getClienteFormateado(servicio.cliente): '',
            servicio.viajero? this.getNombreViajeroFormateado(servicio.viajero): '',
            servicio.referencia? servicio.referencia: '',
            this.getNumeroPasajerosFormateadoServicio(servicio),
            servicio.tipo,
            servicio.numVuelo ? servicio.numVuelo : '',
            servicio.origen,
            servicio.destino,
            servicio.vehiculo ? servicio.vehiculo.matricula : '',
            servicio.descripcion? this.getObservacionesFormateadas(servicio.descripcion): ''
        ];
    }

    private getFilaInformeAgrupacion(agrupacion: IAgrupacionCompartidos) {
        return [
            agrupacion.id,
            DateUtils.parseDateToString(agrupacion.dateFechaInicio, 'H:mm'),
            this.getTrayectoFormateadoAgrupacion(agrupacion),
            '',
            '',
            '',
            this.getNumeroPasajerosFormateadoAgrupacion(agrupacion),
            AgrupacionCompartidosBll.getTipo(agrupacion),
            agrupacion.numVuelo ? agrupacion.numVuelo : '',
            agrupacion.origen,
            agrupacion.destino,
            agrupacion.vehiculo ? agrupacion.vehiculo.matricula : '',
            this.getObservacionesAgrupacion(agrupacion)
        ];
    }

    private setBordesLineasGrupo(grupoConductor: MyReportGroup, servicios) {
        if (servicios.length > 0) {
            let bordes = [];

            for (let i = 0; i < servicios.length; i++) {
                bordes[i] = {
                    color: 'black',
                    width: 1
                };
            }
            grupoConductor.setBordesLineasContenido(bordes);
        }
    }

    private addColumnasGrupo(grupo: MyReportGroup) {
        grupo.addColumna('#');
        grupo.addColumna('Hora');
        grupo.addColumna('Trayecto', undefined, 115);
        grupo.addColumna('Cliente');
        grupo.addColumna('Viajero');
        grupo.addColumna('Ref.');
        grupo.addColumna('Pax', undefined, undefined, 'center');
        //grupo.addColumna('Extras');
        //grupo.addColumna('IDM');
        grupo.addColumna('T');
        grupo.addColumna('Vuelo');
        grupo.addColumna('Origen', undefined, '*');
        grupo.addColumna('Destino', undefined, '*');
        grupo.addColumna('Veh.');
        grupo.addColumna('Observaciones', undefined, undefined, 'right');
    }

    private setFilasGrupoRuta(grupoRuta: MyReportGroup, eventos: EventoScheduler[]) {
        let fechaAnteriorServicio: Date = null;

        eventos.sort((evento1, evento2) => evento1.start_date.getTime() - evento2.start_date.getTime());
        eventos.forEach(evento => {
            let fechaServicio = evento.start_date;

            if (fechaAnteriorServicio == null || !DateUtils.esElMismoDia(fechaServicio, fechaAnteriorServicio)) {
                let diaSemana = DateUtils.getNombreDiaSemana(fechaServicio);
                diaSemana = diaSemana.charAt(0).toUpperCase() + diaSemana.slice(1);
                let cabecera = diaSemana + ' ' + DateUtils.parseDateToString(fechaServicio, 'DD/MM/YYYY');

                grupoRuta.addCabeceraSubgrupo(cabecera, 9, true, 'left');
            }

            let fila = this.getFilaInforme(evento);
            grupoRuta.addFilaContenido(fila, 7);

            fechaAnteriorServicio = fechaServicio;
        });
    }

    private getGrupoRuta(ruta: RutaEventos/* , saltoPagina: string */) {
        let grupoRuta = new MyReportGroup();
        let conductorAsignado = ruta.getConductor();
        let eventosRuta = ruta.getEventos();
        let areaTrabajoRuta = ruta.getAreaTrabajo();

        //grupoRuta.setSaltoPagina(saltoPagina);
        this.addColumnasGrupo(grupoRuta);

        let titulo = 'RUTA #' + ruta.getId() + ' [' + (areaTrabajoRuta? areaTrabajoRuta.nombre: 'SIN ÁREA DE TRABAJO') + ']\n';
        titulo += conductorAsignado && ruta.getConfirmacionAsignacion() ?
            this.getNombreConductorFormateado(conductorAsignado) :
            '(SIN CONDUCTOR ASIGNADO)';

        grupoRuta.setTituloGrupo(titulo, 'left');

        this.setBordesLineasGrupo(grupoRuta, eventosRuta);
        this.setFilasGrupoRuta(grupoRuta, eventosRuta);

        return grupoRuta;
    }

    private addGruposRutas(rutasServicios: RutaEventos[]) {
        /* let saltoPagina = 'after'; */

        rutasServicios.forEach((ruta) => {
            let grupoConductor = this.getGrupoRuta(ruta/* , saltoPagina */);
            this.addGrupo(grupoConductor);

            /* saltoPagina = 'before'; */
        });
    }

    public printReport(rutasServicios: RutaEventos[]) {
        rutasServicios.sort((ruta1, ruta2) => ruta1.getId() - ruta2.getId());

        let nombreEmpresa = this.getNombreEmpresaDelLogo();

        this.setTextoIzquierdaHeader('Agenda rutas de servicios');
        this.setTextoIzquierdaFooter(nombreEmpresa);
        this.addGruposRutas(rutasServicios);

        this.print();
    }
}
