import { IAreaTrabajo } from "./entity/area-trabajo.interface";
import { IConductor } from "./entity/conductor.interface";
import { IEstadoServicio } from "./entity/estado-servicio.interface";
import { ITipoServicio } from "./entity/tipo-servicio.interface";
import { IVehiculo } from "./entity/vehiculo.interface";

export class DatosFiltroAsigScheduler {

    horaInicio: Date;
    fechaInicio: Date;
    fechaHoraInicio: Date;
    horaFin: Date;
    fechaFin: Date;
    fechaHoraFin: Date;
    estado: IEstadoServicio;
    conductores: IConductor[];
    vehiculos: IVehiculo[];
    areasTrabajo: IAreaTrabajo[];
    tiposServicio: ITipoServicio[];
    relacionAeropuerto: { label: string, value: number } | null;
    jornadaConductores: { label: string, value: string } | null;

    constructor() {
        this.horaInicio = null;
        this.fechaInicio = null;
        this.fechaHoraInicio = null;
        this.horaFin = null;
        this.fechaFin = null;
        this.fechaHoraFin = null;
        this.estado = null;
        this.conductores = [];
        this.vehiculos = [];
        this.areasTrabajo = [];
        this.tiposServicio = [];
        this.relacionAeropuerto = null;
    }
}