import { RutaEventos } from "../../business-logic-layer/asignacion-automatica-servicios/ruta-servicios.class";
import { IAreaTrabajo } from "../../entity/area-trabajo.interface";
import { IConductor } from "../../entity/conductor.interface";
import { ITipoServicio } from "../../entity/tipo-servicio.interface";
import { IVehiculo } from "../../entity/vehiculo.interface";
import { DateUtils } from "../../utils/date.util";
import { LocationUtils } from "../../utils/location.util";
import { IEventoScheduler } from "./evento-scheduler.interface";

type OpcionesEventoScheduler = {
    modificado: boolean;
};

export enum TipoDataEventoScheduler {
    SERVICIO,
    AGRUPACION
};

export abstract class EventoScheduler implements IEventoScheduler {

    static readonly COLOR_DEFAULT = '#626262';
    static readonly COLOR_PREASIGNADO_SIN_CONFIRMAR = '#0000003f';

    static readonly ESTADO_SIN_ASIGNAR = 0;
    static readonly ESTADO_ASIGNADO = 1;
    static readonly ESTADO_PREASIGNADO = 2;

    static readonly CONTEXT_MENU_OPCION_ASIGNAR_VEHICULO = "AsignarVehiculo";
    static readonly CONTEXT_MENU_OPCION_ELIMINAR_VEHICULO = "EliminarrVehiculo";
    //static readonly CONTEXT_MENU_OPCION_VER_DETALLES = "VerDetalles";
    static readonly CONTEXT_MENU_OPCION_EDITAR = "Editar";


    public id: string;
    public start_date: Date;
    public end_date: Date;
    public text: string;
    public section_id: string;
    public estado: number;
    public color: string;
    public opciones: OpcionesEventoScheduler;

    public data: any;
    public readonly TIPO_DATA: TipoDataEventoScheduler;

    constructor(
        id: string,
        data: any,
        tipoData: TipoDataEventoScheduler,
        section_id: string,
        text: string,
        opciones: OpcionesEventoScheduler = { modificado: false },
        estado: number
    ) {
        this.id = id;
        this.data = data;
        this.TIPO_DATA = tipoData;
        this.section_id = section_id;
        this.text = text;
        this.opciones = opciones;

        this.sincronizar();

        if (estado) {
            this.estado = estado;
        }
        else if (this.getIdConductores().length > 0) {
            this.estado = EventoScheduler.ESTADO_ASIGNADO;
        }
        else {
            this.estado = EventoScheduler.ESTADO_SIN_ASIGNAR;
        }

        let colorHex = this.getTipoServicio()?.colorHex;
        this.color = colorHex ? colorHex : EventoScheduler.COLOR_DEFAULT;
    }

    public abstract sincronizar();

    public abstract clone();

    public clonePreasignadoConfirmado(newId: string, newSectionId: string) {
        let newEvento = this.clone();

        newEvento.estado = EventoScheduler.ESTADO_PREASIGNADO;
        newEvento.id = newId;
        newEvento.section_id = newSectionId;

        return newEvento;
    }

    public clonePreasignadoSinConfirmado(newId: string, newSectionId: string) {
        let nuevoEv = this.clonePreasignadoConfirmado(newId, newSectionId);
        nuevoEv.color = EventoScheduler.COLOR_PREASIGNADO_SIN_CONFIRMAR;
        return nuevoEv;
    }

    public abstract getTemplateTooltip(): string;

    public abstract getTemplateBar(fechaLimiteSchedulerInicial: Date, fechaLimiteSchedulerFinal: Date): string;

    public getSegundosInicialesFueraDeRango(fechaMinima: Date): number {
        let segundosInicialesFueraDeRango = DateUtils.getSegundosEntreFechas(this.start_date, fechaMinima);
        segundosInicialesFueraDeRango = segundosInicialesFueraDeRango >= 0 ? segundosInicialesFueraDeRango : 0;

        return segundosInicialesFueraDeRango;
    }

    public getSegundosFinalesFueraDeRango(fechaMaxima: Date): number {
        let segundosFinalesFueraDeRango = DateUtils.getSegundosEntreFechas(fechaMaxima, this.end_date);
        segundosFinalesFueraDeRango = segundosFinalesFueraDeRango >= 0 ? segundosFinalesFueraDeRango : 0;

        return segundosFinalesFueraDeRango;
    }

    public abstract getCambios(eventoInicial: IEventoScheduler): { id: number, mensajesCambios: string[] };

    public seHaModificado(eventoInicial: IEventoScheduler): boolean {
        return this.getCambios(eventoInicial).mensajesCambios.length > 0;
    }

    public abstract asignarIdsConductores(idConductores: number[]);

    public abstract asignarVehiculo(vehiculo: IVehiculo);

    public abstract getOpcionesMenuContextual(): { label: string; value: string; }[];

    public abstract esCompatibleConVehiculo(vehiculo: IVehiculo): boolean;

    public abstract esCompatibleConConductor(conductor: IConductor): boolean;

    public abstract esCompatibleConRuta(ruta: RutaEventos): boolean;

    public abstract getIdConductores(): number[];

    public abstract getVehiculo(): IVehiculo | null;

    public abstract getTipoServicio(): ITipoServicio | null;

    public abstract getAreaTrabajo(): IAreaTrabajo | null;

    public abstract getDataId(): number;

    public estaSolapadoEnHorario(evento: IEventoScheduler): boolean {

        let estanSolapados = (evento.start_date >= this.start_date && evento.start_date <= this.start_date)
            ||
            (evento.end_date >= this.start_date && evento.end_date <= this.end_date)
            ||
            (this.start_date >= evento.start_date && this.start_date <= evento.end_date)
            ||
            (this.end_date >= evento.start_date && this.end_date <= evento.end_date);

        if (!estanSolapados) {
            let eventoAnterior = evento;
            let eventoPosterior: IEventoScheduler = this;

            if (evento.end_date > this.start_date) {
                eventoAnterior = this;
                eventoPosterior = evento;
            }

            let minutosEntreEventos = DateUtils.getMinutosEntreFechas(eventoAnterior.end_date, eventoPosterior.start_date);
            let minutosEstimadosEntreServicios = eventoAnterior.getEstimacionMinutosPorKilometroDistanciaSiguienteEvento(eventoPosterior);

            estanSolapados = minutosEstimadosEntreServicios > minutosEntreEventos;
        }

        return estanSolapados;
    }

    public getEstimacionDistanciaKilometrosSiguienteEvento(siguienteEvento: IEventoScheduler): number {
        let destino = this.getCoordenadasDestino();
        let siguienteOrigen = siguienteEvento.getCoordenadasOrigen();

        if (destino.latitud && destino.longitud && siguienteOrigen.latitud && siguienteOrigen.longitud) {
            return LocationUtils.getKilometrosEntreCoordenadas(
                destino.latitud,
                destino.longitud,
                siguienteOrigen.latitud,
                siguienteOrigen.longitud
            );
        }

        return 0;
    }

    public getEstimacionMinutosPorKilometroDistanciaSiguienteEvento(siguienteEvento: IEventoScheduler): number {
        let tipoServicio = this.getTipoServicio();

        return tipoServicio ?
            this.getEstimacionDistanciaKilometrosSiguienteEvento(siguienteEvento) * tipoServicio.estimacionMinutosPorKilometroDistanciaEnLineaRecta :
            0;
    }

    public abstract getCoordenadasOrigen(): { latitud: number; longitud: number; };

    public abstract getCoordenadasDestino(): { latitud: number; longitud: number; };
}
