import { IAreaTrabajo } from "../../entity/area-trabajo.interface";
import { IConductor } from "../../entity/conductor.interface";
import { IServicio } from "../../entity/servicio.interface";
import { IPermisoConduccion } from "../../entity/permiso-conduccion.interface";
import { IVehiculo } from "../../entity/vehiculo.interface";
import { DateUtils } from "../../utils/date.util";
import { EventoScheduler } from "../../helper/dhtmlxHelper/evento-scheduler.class";
import { EventoSchedulerBll } from "../evento-scheduler.bll";
import { IAgrupacionCompartidos } from "../../entity/agrupacion-compartidos.interface";

export class RutaEventos {
    private id: number;
    private eventos: EventoScheduler[];
    private conductorAsignado: IConductor | null;
    private areaTrabajo: IAreaTrabajo;
    private asignacionConfirmada: boolean;

    public constructor(id: number, eventos?: EventoScheduler[], conductor?: IConductor) {
        this.id = id;
        this.asignacionConfirmada = false;
        this.setEventos(eventos ? eventos : []);
        this.setConductor(conductor ? conductor : null);
    }

    public static fromJsonObject(objeto: any): RutaEventos {
        let ruta = new RutaEventos(objeto.id);
        Object.assign(ruta, objeto);
        ruta.setEventos(ruta.getEventos().map(evento => EventoSchedulerBll.fromJsonObject(evento)));

        return ruta;
    }

    public getEventos(): EventoScheduler[] {
        return this.eventos;
    }

    public getServicios(): IServicio[] {
        return this.getEventos()
            .filter(evento => evento instanceof EventoScheduler)
            .map(evento => (evento as EventoScheduler).data);
    }

    public setEventos(eventos: EventoScheduler[]): void {
        if (eventos.every((evento) => evento.esCompatibleConRuta(this))) {
            this.eventos = eventos;
            this.actualizarAreaTrabajoRuta();
            this.actualizarConductoresEventos();
        }
    }

    public addEvento(evento: EventoScheduler): void {
        if (evento.esCompatibleConRuta(this)) {
            this.eventos.push(evento);
            this.actualizarAreaTrabajoRuta();
            this.actualizarConductoresEventos();
        }
    }

    public removeEvento(idEvento: string): void {
        let index = this.eventos.findIndex((evento) => evento.id === idEvento);

        if (index >= 0) {
            this.eventos[index].asignarIdsConductores([]);
            this.eventos.splice(index, 1);
            this.actualizarAreaTrabajoRuta();
        }
    }

    public getConductor(): IConductor | null {
        return this.conductorAsignado;
    }

    public setConductor(conductor: IConductor): void {
        if (!conductor || this.esCompatibleConConductor(conductor)) {
            this.conductorAsignado = conductor;

            if (!conductor)
                this.desconfirmarAsignacion();

            this.actualizarAreaTrabajoRuta();
            this.actualizarConductoresEventos();
        }
    }

    public confirmarAsignacion() {
        this.asignacionConfirmada = true;
        this.actualizarConductoresEventos();
    }

    public desconfirmarAsignacion() {
        this.asignacionConfirmada = false;
        this.actualizarConductoresEventos();
    }

    public getConfirmacionAsignacion(): boolean {
        return this.asignacionConfirmada;
    }

    public getAreaTrabajo(): IAreaTrabajo | null {
        return this.areaTrabajo;
    }

    public getPermisoConduccionMax(): IPermisoConduccion | null {
        let permisoConduccionMax: IPermisoConduccion = null;

        if (this.eventos.length > 0) {
            let eventoConMayorPermCond = this.eventos.filter(evento => evento.getTipoServicio() !== null)
                .reduce((eventoPrevio, eventoActual) =>
                    eventoPrevio.getTipoServicio().permisoConduccion.nivel > eventoActual.getTipoServicio().permisoConduccion.nivel ? eventoPrevio : eventoActual
                );

            permisoConduccionMax = eventoConMayorPermCond ? eventoConMayorPermCond.getTipoServicio().permisoConduccion : null;
        }

        return permisoConduccionMax;
    }

    private actualizarAreaTrabajoRuta() {
        let primerEventoConAreaTrabajo = this.eventos.find(evento => evento.getAreaTrabajo() !== null);

        if (primerEventoConAreaTrabajo)
            this.areaTrabajo = primerEventoConAreaTrabajo.getAreaTrabajo();
        else if (this.conductorAsignado && this.conductorAsignado.areaTrabajo)
            this.areaTrabajo = this.conductorAsignado.areaTrabajo;
        else
            this.areaTrabajo = null;
    }

    private actualizarConductoresEventos() {
        let valorConductoresAsignados = (this.conductorAsignado && this.asignacionConfirmada) ?
            [this.conductorAsignado.id] :
            [];
        this.eventos.forEach((evento) => evento.asignarIdsConductores(valorConductoresAsignados));
    }

    public getId(): number {
        return this.id;
    }

    public isEmpty(): boolean {
        return this.eventos.length === 0;
    }

    private existeEventoSolapadoEnHorario(evento: EventoScheduler): boolean {
        return this.getEventos().some(eventoRuta => eventoRuta.estaSolapadoEnHorario(evento));
    }

    public sePuedeAsignarAutomaticamenteEvento(evento: EventoScheduler, duracionMaxMinutosRuta: number = 0): boolean {
        let esCompatible = true;

        esCompatible = evento.esCompatibleConRuta(this) && !this.existeEventoSolapadoEnHorario(evento);

        if (esCompatible && duracionMaxMinutosRuta > 0 && this.getServicios().length > 0) {
            let primerEventoRuta = this.getPrimerEvento();
            let minutosDuracionRuta = primerEventoRuta.start_date.getTime() < evento.start_date.getTime() ?
                DateUtils.getMinutosEntreFechas(primerEventoRuta.start_date, evento.end_date) :
                DateUtils.getMinutosEntreFechas(evento.start_date, primerEventoRuta.end_date);

            esCompatible = minutosDuracionRuta <= duracionMaxMinutosRuta;
        }

        return esCompatible;
    }

    public esCompatibleConServicio(servicio: IServicio): boolean {
        let esCompatible = true;
        let areaTrabajoRuta = this.getAreaTrabajo();
        let conductorRuta = this.getConductor();

        if (areaTrabajoRuta && servicio.areaTrabajo && areaTrabajoRuta.id !== servicio.areaTrabajo.id)
            esCompatible = false;
        else if (servicio.tipoServicio && conductorRuta && conductorRuta.permisoConduccion.nivel < servicio.tipoServicio.permisoConduccion.nivel)
            esCompatible = false;

        return esCompatible;
    }

    public esCompatibleConAgrupacion(agrupacion: IAgrupacionCompartidos): boolean {
        let esCompatible = true;
        let areaTrabajoRuta = this.getAreaTrabajo();
        let conductorRuta = this.getConductor();

        if (areaTrabajoRuta && agrupacion.areaTrabajo && areaTrabajoRuta.id !== agrupacion.areaTrabajo.id)
            esCompatible = false;
        else if (agrupacion.tipoServicio && conductorRuta && conductorRuta.permisoConduccion.nivel < agrupacion.tipoServicio.permisoConduccion.nivel)
            esCompatible = false;

        return esCompatible;
    }

    private getPrimerEvento(): EventoScheduler | null {
        return this.getEventos()
            .reduce((eventoPrevio, eventoActual) =>
                eventoActual.start_date.getTime() < eventoPrevio.start_date.getTime() ?
                    eventoActual :
                    eventoPrevio
            );
    }

    public esCompatibleConVehiculo(vehiculo: IVehiculo): boolean {
        let esCompatible = true;
        let areaTrabajoRuta = this.getAreaTrabajo();


        if (areaTrabajoRuta && vehiculo.areaTrabajo && areaTrabajoRuta.id !== vehiculo.areaTrabajo.id)
            esCompatible = false;
        else if (!this.getEventos().every(((evento) => evento.esCompatibleConVehiculo(vehiculo))))
            esCompatible = false;

        return esCompatible;
    }

    public esCompatibleConConductor(conductor: IConductor): boolean {
        let esCompatible = true;
        let areaTrabajoRuta = this.getAreaTrabajo();

        if (areaTrabajoRuta && conductor.areaTrabajo && areaTrabajoRuta.id !== conductor.areaTrabajo.id)
            esCompatible = false;
        else if (!this.getEventos().every((evento) => evento.esCompatibleConConductor(conductor)))
            esCompatible = false;

        return esCompatible;
    }
}
