export abstract class StringUtils {

    public static cleanHtml(texto: string) {
        texto = texto.replace(/<div.*?>/gi, "\n");
        texto = texto.replace(/<br>/gi, "\n");
        texto = texto.replace(/<p.*?>/gi, "\n");
        texto = texto.replace(/&nbsp;/gi, "\n");
        texto = texto.replace(/<a.*href="(.*?)".*>(.*?)<\/a>/gi, " $2 (Link->$1) ");
        texto = texto.replace(/<(?:.|\s)*?>/g, "");
        
        if (texto.startsWith('\n'))
            texto = texto.substring(1);
    
        return texto;
    }
}